


import { getKeysPixApi, createWithdrawSox, createKeyPix } from "@/services/withdraw/index.js";
import { getPlatform } from "@/services/platforms/index.js";
import { showNotificationError, showNotificationSuccess } from "@/services/notifications/index.js";

const state = {
    showSteps: false,
    formDataRegisterKey: {
        key: "",
        type: "CPF",
        name: "",
    },
    formDataWithdraw: {
        club: "",
        value: 0,
        key: "",
        idPlayer: 0,
        idClub: 0,
        platformId: 0
    },
    formDataWithdrawChips: {
        valueChips: 30
    },
    formDataSelectAccount: {
        accounts: []
    },
    accountSelected: null,

    currentStep: 1,
    loading: false,
    qrCode: {
        text: "",
        hash: "",
        idCob: ""
    }
};

const getters = {
    // getters if needed
};

const mutations = {
    SET_SHOW_STEPS(state) {
        state.showSteps = !state.showSteps;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_NEXT_CURRENT_STEP(state) {
        state.currentStep = state.currentStep + 1;
    },

    SET_PREVIOUS_CURRENT_STEP(state) {
        state.currentStep = state.currentStep - 1;
    },

    SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
    },

    SET_ACCOUNTS(state, accounts) {
        state.formDataSelectAccount.accounts = accounts;
    },

    SET_FORM_DATA_WITHDRAW(state, payload) {
        state.formDataWithdraw = payload;
    },
    SET_ACCOUNT_SELECTED(state, account) {
        state.accountSelected = account;
    },


};

const actions = {
    async saveStepNonValidation({ commit }) {
        commit("SET_NEXT_CURRENT_STEP");
    },
    async saveKeyPix({ state, commit, rootState }) {
        commit("SET_LOADING", true)
        const idUserAccount = rootState.login.userLogged.id;
        let data = {
            userId: idUserAccount,
            type: state.formDataRegisterKey.type,
            key: state.formDataRegisterKey.key,
            pixAccountNickname: state.formDataRegisterKey.name
        }
        try {
            await createKeyPix(data);
            commit("SET_LOADING", false)
            showNotificationSuccess('Chave PIX cadastrada com sucesso!')
            const idPlayer = rootState.login.playerId;
            const idClub = rootState.login.idClub;
            const club = rootState.login.club;
            const platformId = rootState.login.platformId;
            let withdrawData = {
                value: state.formDataWithdrawChips.valueChips,
                key: state.formDataRegisterKey.key,
                idPlayer: idPlayer,
                idClub: idClub,
                platformId: platformId,
                club: club
            }
            commit("SET_ACCOUNT_SELECTED", data)
            commit("SET_FORM_DATA_WITHDRAW", withdrawData)
            commit("SET_CURRENT_STEP", 4)

        } catch (error) {
            console.error('Erro ao cadastrar chave PIX:', error.message);
            commit("SET_LOADING", false)
            showNotificationError('Erro ao cadastrar chave PIX. Tente novamente mais tarde.')
        }
    },
    async getKeysPix({ commit, rootState }) {
        commit("SET_LOADING", true)
        try {
            const idUserAccount = rootState.login.userLogged.id;
            let result = await getKeysPixApi(idUserAccount);
            console.log(result)
            if (result.length === 0) {
                commit("SET_CURRENT_STEP", state.currentStep + 2)
                return
            } else {
                commit("SET_NEXT_CURRENT_STEP")

            }

            commit("SET_ACCOUNTS", result)

        } catch (error) {
            console.error('Erro ao buscar chaves PIX:', error);
            showNotificationError('Erro ao buscar chaves PIX. Tente novamente mais tarde.')
        }
        commit("SET_LOADING", false)
    },

    async getInfoToWithdraw({ state, commit, rootState }, payload) {
        commit("SET_ACCOUNT_SELECTED", payload)
        const idPlayer = rootState.login.playerId;
        const idClub = rootState.login.idClub;
        const club = rootState.login.club;
        const platformId = rootState.login.platformId;
        let withdrawData = {
            value: state.formDataWithdrawChips.valueChips,
            key: payload.key,
            idPlayer: idPlayer,
            idClub: idClub,
            platformId: platformId,
            club: club
        }

        commit("SET_FORM_DATA_WITHDRAW", withdrawData)
        commit("SET_CURRENT_STEP", 4)
    },
    async createWithdraw({ state, commit }) {
        commit("SET_LOADING", true)
        let userLogged = JSON.parse(localStorage.getItem("userLogged"))
        let typeDoc = userLogged.inscription.replace(/[^\d]+/g, '').length === 11 ? "CPF" : "CNPJ";

        let data = {
            id_client_f24h: userLogged.id,
            nome: userLogged.name,
            playerid: state.formDataWithdraw.idPlayer,
            clube: state.formDataWithdraw.club,
            tipo_pix: state.accountSelected.type,
            chave_pix: state.formDataWithdraw.key,
            titular: userLogged.name,
            plataforma: getPlatform(state.formDataWithdraw.platformId),
            doc: typeDoc,
            cpfcnpj: userLogged.inscription,
            valor: state.formDataWithdraw.value,
            ddi: 55,
            celular: userLogged.phone,
            email: userLogged.email,
        }
        try {
            await createWithdrawSox(data);

            commit("SET_LOADING", false)
            return true

        } catch (error) {
            console.error('Erro ao fazer saque:', error);
            commit("SET_LOADING", false)
            showNotificationError('Erro ao fazer saque. Tente novamente mais tarde.')
            return false
        }
    },




};




export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
